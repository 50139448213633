<script>
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required, numeric } from "vuelidate/lib/validators";

export default {
    props:['model'],
    components: { 
      Multiselect,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
    },
    data(){
        return {
            tableData:[],
            modalShow:false,
            submitted:false,
            isLoading:false,
            users:[],
            selectedUser:[],
            selectedRows:[],
            selectedIndex:[],
            titleModal:'Ajouter un niveau de validation',
            cols:[
                {
                    name: 'N°',
                    field: 'id',
                    sort: ''
                },
                {
                    name: 'Utilisateur',
                    field: 'user',
                    sort: ''
                },
                {
                    name: 'Validation Obligatoire',
                    field: 'isRequired',
                    sort: ''
                },
                {
                    name: 'Niveau',
                    field: 'level',
                    sort: ''
                },
                {
                    name: 'Action',
                    field: 'action',
                    sort: ''
                },
            ],
            dataForm:{
                model:this.model,
                user_id:"",
                is_required:false,
                level:""
            }
        }
    },
    validations:{
        dataForm:{
            user_id:{required},
            level:{required},
        }
    },
    mounted(){
        this.getLevels();
    },
    methods:{
        selectRow(row,index){
            if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1}
            else {
                this.selectedIndex = index,
                this.selectedRows = row,
                this.selectedUser=row.user,
                this.dataForm = {
                    user_id:row.user.id,
                    level:row.level,
                    is_required:row.is_required == 1 ? true : false
                }
            }
            
        },
        getLevels(){
            this.$http.post('/system/validationsLevels/index',{model:this.model})
            .then(response => {
                this.tableData = response.data;
            })
            .catch(error => {console.log(error);})
        },
        clearForm(){
            this.dataForm={
                model:this.model,
                user_id:"",
                is_required:false,
                level:""
            }
            this.titleModal='Ajouter un niveau de validation';
        },
        getUser(row){
            this.dataForm.user_id = row.id;
        },
        asyncFind(query){
            if(query.length >=3){
                this.isLoading = true
                this.$http.post('/system/users/selectSearch',{q:query})
                .then(response=>{
                    // console.log(response);
                    this.users = response.data;
                    this.isLoading = false
                    return this.users.length > 0;
                })
                .catch(error => {console.log(error);this.isLoading = false})
            }
        },
        addLevel(){
             this.$http.post('/system/validationsLevels/store',this.dataForm)
            .then(response=>{
                if(this.dataForm.id)this.$toast.success('Niveau De validation Modifié avec succès')
                else this.$toast.success('Niveau De validation Enregistré avec succès')
                this.getLevels();
                this.clearForm();
                this.modalShow = false;
            })
            .catch(error => {console.log(error)})
        },
        editModal(row){
            console.log(this.asyncFind(row.user.username))
            if(this.asyncFind(row.user.username)){
                console.log(this.users.filter(element => (element.id == row.user.id)));
                // this.selectedUser = this.users.filter(element => (element.id == row.user.id));
            } 
            this.dataForm     = {
                id         : row.id,
                model      : this.model,
                user_id    : row.user.id,
                level      : row.level,
                is_required: row.is_required == 1 ? true: false
            }
            this.titleModal   = "Modifier Un Niveau de Validation";
            this.modalShow    = true;
        },
        deleteLevel(row){
            var _this = this;
                Swal.fire({
                    title: "Êtes-vous sûr de Supprimé ce niveau?",
                    text: "Cette Action est irréversible",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor:"#f46a6a",
                    cancelButtonColor:  "#34c38f",
                    confirmButtonText: 'Oui!',
                    cancelButtonText: 'Annuler!',
                }).then((result) => {
                    if (result.value) {
                    this.$http
                        .post('/system/validationsLevels/destroy',row)
                        .then((res) => {
                            _this.$toast.success(res.data.msg);
                            _this.getLevels();
                           
                        })
                        .catch((error) => {
                            _this.$toast.error(error.message);
                        })
                        .finally(() => {});
                    }
                });
            }
    }

}
</script>
<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row d-flex justify-content-between">
                <div class="col"><h4> {{model}} </h4></div>
                <div class="col text-right">
                    <b-button  @click="modalShow = true" variant="primary" class="btn-label mr-2">
                        <i class="fas fa-user-plus text-white label-icon"></i> Ajouter
                    </b-button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col">
                    <div class=" mb-0">
                      <dataset
                        v-slot="{ ds }"
                        :ds-data="tableData"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="">
                              <table class="table table-hover d-md-table">
                                <thead>
                                  <tr class="text-center">
                                    <th v-for="(th) in cols" :key="th.field">
                                      {{ th.name }} 
                                    </th>
                                  </tr>
                                </thead>
                                <dataset-item tag="tbody">
                                  <template #default="{ row, rowIndex }">
                                    <!-- <tr @click="selectRow(row,rowIndex)" class="text-center" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer"> -->
                                    <tr class="text-center">
                                      <th>
                                        {{ rowIndex+1 }}
                                      </th>
                                      <td>{{row.user.firstName}} {{row.user.lastName}}</td>
                                      <td v-if="row.is_required == 1" class="text-success"><i  class="fas fa-check-circle text-success"></i> {{row.read_at}}</td>
                                      <td v-else><i class="fas fa-times-circle text-danger"></i></td>
                                      <td>{{ row.level }}</td>
                                      <td>
                                        <span @click="editModal(row)" class="text-primary mr-2" style="cursor:pointer">
                                          <i class="fas fa-edit"></i>
                                        </span>
                                        <span @click="deleteLevel(row)" class="text-danger ml-2" style="cursor:pointer">
                                          <i class="fas fa-trash-alt"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                </dataset-item>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                          <dataset-show :ds-show-entries="5" />
                          <dataset-pager />
                        </div>
                      </dataset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-model="modalShow"
            :title="`${titleModal}: ${model}`"
            @close="clearForm"
            >
            <!-- <form @submit.prevent="saveNewDropdownEntry"> -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Utilisateur</label>
                            <multiselect 
                                v-model="selectedUser" 
                                @select="getUser" 
                                deselect-label="" 
                                track-by="id" 
                                label="full_name" 
                                placeholder="Select one" 
                                :options="users" 
                                :searchable="true" 
                                :allow-empty="true"
                                @search-change="asyncFind"
                                :close-on-select="true"
                                :loading="isLoading"
                                :multiple="false"
                                id="ajax"
                            >
                                <span slot="noResult">Aucun Utilisateur Trouvé.</span>
                            </multiselect>
                            <div
                                v-if="submitted && $v.dataForm.user_id.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.dataForm.user_id.required"
                                >Champs Obligatoire.</span
                                >
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Niveau</label>
                            <input type="text" class="form-control" v-model="dataForm.level">
                            <div
                                v-if="submitted && $v.dataForm.level.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.dataForm.level.required"
                                >Champs Obligatoire.</span
                                >
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-form-checkbox v-model="dataForm.is_required" name="check-button" switch>
                            Validation Obligatoire
                        </b-form-checkbox>
                    </div>
                </div>
                <br />

                <hr />
                <div class="row">
                <div class="col-md-12 text-right">
                    <b-button  @click="addLevel()" variant="primary" class="btn-label mr-2">
                        <i class="fas fa-save text-white label-icon"></i> Enregistrer
                    </b-button>
                </div>
                </div>
            <!-- </form> -->
        </Modal>
    </div>
</template>